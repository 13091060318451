import React from 'react';
import { Routes as ReactRoutes, Route } from 'react-router-dom';
import Landing from './components/views/Landing';
import NotFound from './components/views/NotFound';

const Routes = (): JSX.Element => {
  return (
    <ReactRoutes>
      <Route path="/" element={<Landing />} />
      <Route path="*" element={<NotFound />} />
    </ReactRoutes>
  );
};

export default Routes;
