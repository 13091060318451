/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { etherauthority, assure, hazecrypto } from 'assets';
import { ATHAME_FINANCE_TOKEN, TOKEN_AUDIT_V1, KYC_URL, AUDIT_V1 } from '../../../../constants';

const audits = [
  {
    name: 'Formal Verification',
    icon: assure,
    href: KYC_URL
  },
  {
    name: 'Depository Audit Report',
    icon: etherauthority,
    href: AUDIT_V1
  },
  {
    name: 'Treasury Audit Report',
    icon: etherauthority,
    href: AUDIT_V1
  },
  {
    name: `${ATHAME_FINANCE_TOKEN} Token Audit Report`,
    icon: hazecrypto,
    href: TOKEN_AUDIT_V1
  }
];

const Audit = (): JSX.Element => {
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box minHeight='95vh'>
      <Box marginBottom={2}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'secondary'}
          align={'center'}
        >
          Audit
        </Typography>
        <Typography variant={'h4'} sx={{ fontWeight: 700 }} align={'center'}>
          Audited and Verified
        </Typography>
        <Typography
          variant="h6"
          component="p"
          color="text.secondary"
          align={'center'}
        >
          Athame's security is our top priority; we have worked with third-party auditors and consultants to develop a protocol that we believe is safe and reliable.
        </Typography>
      </Box>
      <Grid item container spacing={1} xs={12} md={12}>
        {audits.map((item, i) => (
          <Grid
            item
            xs={12}
            md={3}
            key={i}
            data-aos="fade-up"
            data-aos-delay={i * 100}
            data-aos-offset={100}
            data-aos-duration={600}
          >
            <Box
              component={'a'}
              href={item.href}
              display={'block'}
              target='_blank'
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Card>
                <CardContent
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    padding: 3,
                    bgcolor: 'alternate.light'
                  }}
                >
                  <Box
                    component={'img'}
                    src={item.icon}
                    height={0.2}
                    width={0.2}
                  />
                  <Typography sx={{ paddingLeft: 1 }}>
                    {item.name}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Audit;
