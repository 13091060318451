import React from 'react';
import Box from '@mui/material/Box';
import { Logo } from '../../../assets';
import NavItem from './NavItem';
import { SingleNavItem } from '.';
import { APP_URL } from '../../../constants';

interface Props {
  pages: Array<PageItem>;
}

const SidebarNav = ({ pages }: Props): JSX.Element => {

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component="a"
          href="/"
          title="theFront"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={'img'}
            src={Logo}
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box paddingY={1}>
          <SingleNavItem
            title='App'
            item={{
              title: 'App',
              href: APP_URL,
              target: '_blank'
            }}
          />
        </Box>
        {pages.map((p) => (
          <Box paddingY={1} key={p.id}>
            {p.subItems ?
              <NavItem
                title={p.title}
                items={p.subItems}
              />
              :
              <SingleNavItem
                title={p.title}
                item={p}
              />
            }
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SidebarNav;
