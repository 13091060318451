import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Slider from 'react-slick';
import Typography from '@mui/material/Typography';
import RoadmapItem from '../../../RoadmapItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import { ATHAME_FINANCE_TOKEN } from '../../../../constants';

const Roadmap = (): JSX.Element => {
  const theme = useTheme();
  const useStyles = makeStyles(() => ({
    dots: {
      bottom: 0,
      '& li.slick-active button::before': {
        color: theme.palette.primary.main
      },
      '& li': {
        '& button::before': {
          fontSize: theme.typography.pxToRem(14),
          color: '#fff',
          opacity: 0.5
        }
      }
    }
  }));
  const classes = useStyles();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const settings = {
    infinite: true,
    slidesToShow: isMd ? 4 : 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    arrows: false,
    dots: true,
    dotsClass: `slick-dots ${classes.dots}`
  };

  return (
    <Box
      minHeight='95vh'
      marginTop={5}
      data-aos={'fade-up'}>
      <Box>
        <Typography
          variant="body2"
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'secondary'}
          align={'center'}
        >
          Timeline
        </Typography>
        <Typography
          variant="h4"
          align={'center'}
          marginBottom={4}
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700,
            marginTop: theme.spacing(1),
          }}
        >
          Roadmap
        </Typography>
        <Box sx={{
          borderTop: `2px dashed ${theme.palette.primary.main}`
        }}>
        </Box>
        <Slider {...settings}>
          <RoadmapItem title='Token' description={`Releasing ${ATHAME_FINANCE_TOKEN} governance token`} date='April 15, 2022' />
          <RoadmapItem title='KYC' description='KYC complete' date='April 21, 2022' />
          <RoadmapItem title='Governance' description='Athame governance will be complete' date='April 22, 2022' />
          <RoadmapItem title='Contracts' description='Deploy contracts to Avalanche Mainnet' date='April 25, 2022' />         
          <RoadmapItem title='Audits' description='Contract security audits complete' date='April 26, 2022' />
          <RoadmapItem title='Release' description='Live' date='April 27, 2022' />
        </Slider>
      </Box>
    </Box>
  );
};

export default Roadmap;