import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

interface Props {
    // eslint-disable-next-line @typescript-eslint/ban-types
    title: string;
    date: string;
    description: string;
}

const RoadmapItem = ({ title, description, date }: Props): JSX.Element => {
    const theme = useTheme();
    return (
        <Box sx={{
            height: '225px'
        }}>
            <Divider orientation="vertical"
                sx={{
                    height: '80px',
                    transform: 'translate(155px, 0)'
                }} />
            <Box component={'span'} sx={{
                background: theme.palette.primary.main,
                width: '10px',
                height: '10px',
                borderRadius: '100%',
                position: 'fixed',
                transform: 'translate(150px, 0)'
            }}>
            </Box>
            <Box sx={{
                width: '300px',
                paddingTop: '10px',
                position: 'fixed'
            }}>
                <Typography gutterBottom variant="body1" component="div" align='center'>
                    {date}
                </Typography>
                <Typography variant="h6" color="text.secondary" align='center'>
                    {title}
                </Typography>
                <Divider variant="middle" sx={{ background: theme.palette.primary.light, height: 2}} />
                <Typography sx={{ marginTop: '10px', color: theme.palette.primary.main }} variant="body2" color="text.secondary" align='center'>
                    {description}
                </Typography>
            </Box>
        </Box>

    );
};

export default RoadmapItem;