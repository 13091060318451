import React from 'react';
import { useTheme } from '@mui/material/styles';

const Btc = (): JSX.Element => {
  const theme = useTheme();
  const colorPrimaryMain = theme.palette.secondary.dark;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      viewBox="0 0 500 500"
    >
     <path fill={colorPrimaryMain} d="M10.271,264.023c0-8.681,0-17.362,0-26.043c0.189-0.972,0.48-1.936,0.551-2.916
	c3.718-51.18,21.213-97.075,53.958-136.625c40.701-49.16,93.064-77.799,156.37-86.291c5.375-0.721,10.774-1.256,16.162-1.877
	c8.681,0,17.362,0,26.043,0c0.966,0.188,1.925,0.485,2.899,0.547c18.393,1.175,36.399,4.498,54.032,9.821
	c93.122,28.115,160.266,109.969,169.59,206.681c4.322,44.829-3.274,87.621-22.904,128.06
	c-54.58,112.438-184.775,164.041-301.805,119.906c-82.863-31.25-142.463-107.221-153.025-195.07
	C11.493,274.821,10.893,269.421,10.271,264.023z M323.76,254.871c16.527-4.162,26.881-14.595,31.257-31.222
	c5.663-21.515-0.123-38.352-17.968-51.458c-9.379-6.889-19.904-11.526-30.776-15.405c-2.075-0.74-2.545-1.643-2.042-3.588
	c1.878-7.268,3.712-14.547,5.535-21.828c1.547-6.18,3.056-12.37,4.662-18.88c-8.885-2.212-17.416-4.336-26.179-6.518
	c-3.533,14.119-7.008,28.006-10.547,42.147c-7.205-1.713-14.14-3.362-21.234-5.048c3.563-14.337,7.02-28.246,10.539-42.404
	c-8.856-2.207-17.381-4.332-26.03-6.488c-0.388,1.156-0.723,1.965-0.933,2.806c-3.134,12.541-6.321,25.069-9.312,37.644
	c-0.561,2.359-1.342,2.77-3.622,2.168c-9.077-2.395-18.206-4.593-27.32-6.844c-7.59-1.875-15.186-3.723-22.983-5.632
	c-2.355,9.433-4.644,18.604-6.97,27.92c2.323,0.564,4.472,1.021,6.585,1.61c5.736,1.602,11.649,2.788,17.133,5.02
	c6.241,2.541,8.803,8.904,7.187,15.417c-4.28,17.251-8.543,34.506-12.842,51.752c-5.313,21.31-10.731,42.595-15.928,63.933
	c-1.157,4.75-6.392,9.244-11.982,7.619c-6.424-1.868-13.026-3.125-19.69-4.68c-4.281,9.868-8.533,19.669-12.921,29.784
	c17.84,4.513,35.435,8.965,53.092,13.431c-3.683,14.843-7.257,29.242-10.885,43.864c8.909,2.213,17.415,4.326,26.036,6.468
	c0.398-1.475,0.698-2.524,0.964-3.582c3.116-12.411,6.254-24.816,9.304-37.243c0.378-1.538,0.81-2.52,2.602-2.042
	c6.265,1.669,12.532,3.332,19.195,5.102c-3.573,14.426-7.107,28.692-10.682,43.124c8.952,2.229,17.452,4.346,25.982,6.471
	c0.378-1.119,0.718-1.934,0.929-2.782c3.15-12.637,6.339-25.264,9.369-37.929c0.56-2.341,1.42-3.127,3.835-2.528
	c2.565,0.637,5.23,0.864,7.848,1.288c13.552,2.192,27.142,2.991,40.724,0.473c12.264-2.274,22.761-7.764,30.623-17.743
	c8.456-10.733,12.803-23.1,14.663-36.481c1.908-13.728-1.556-25.84-11.605-35.494C334.649,262.555,329.032,258.947,323.76,254.871z
	 M229.444,254.07c-4.652,18.523-9.274,37.053-13.957,55.568c-0.475,1.879,0.334,2.211,1.927,2.558
	c10.567,2.299,21.036,5.132,31.68,6.96c9.519,1.634,19.2,1.873,28.785-0.357c18.859-4.389,24.313-25.411,14.958-38.116
	c-4.389-5.961-10.336-9.999-16.871-13.234c-14.59-7.224-30.416-10.364-46.034-14.005
	C229.704,253.728,229.492,253.879,229.444,254.07z M249.441,230.363c11.677,2.763,23.414,4.895,35.491,2.951
	c17.503-2.817,22.678-18.232,19.751-29.462c-2.064-7.92-7.911-12.855-14.735-16.74c-11.994-6.829-25.375-9.425-38.577-12.559
	c-2.006-0.476-2.359,0.798-2.733,2.347c-1.628,6.753-3.359,13.482-5.034,20.224c-2.444,9.835-4.873,19.673-7.392,29.846
	C240.926,228.184,245.165,229.351,249.441,230.363z"/>
    </svg>
  );
};

export default Btc;
