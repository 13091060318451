import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
  eth, ftm,
  matic, avax,
  bnb,
  ada, rune,
  arb, sol
} from 'assets';

export const blockchains = [
  {
    icon: avax,
    name: 'Avalanche',
  },
  {
    icon: matic,
    name: 'Polygon',
  },
  {
    icon: eth,
    name: 'Ethereum',
  },
  {
    icon: ftm,
    name: 'Fantom',
  },
  {
    icon: sol,
    name: 'Solana',
  },
  {
    icon: arb,
    name: 'Arbitrum',
  },
  {
    icon: ada,
    name: 'Cardano',
  },
  {
    icon: rune,
    name: 'Thorchain',
  },
  {
    icon: bnb,
    name: 'Binance',
  }
];

const Blockchains = (): JSX.Element => {
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box minHeight='95vh'
      marginTop={5}>
      <Grid container spacing={4}>
        <Grid item container xs={12} md={6} alignItems={'center'}>
          <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
            <Typography
              sx={{
                textTransform: 'uppercase',
                fontWeight: 'medium',
              }}
              gutterBottom
              color={'secondary'}
            >
              Blockchains
            </Typography>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 700 }}>
              Athame Finance invests in DeFi projects on various blockchains
            </Typography>
            <Typography variant="h6" component="p" color="text.secondary">
              Our mission is to use any and all blockchains to invest your capitol.
            </Typography>
          </Box>
        </Grid>
        <Grid item container spacing={2} xs={12} md={6}>
          {blockchains.map((item, i) => (
            <Grid
              item
              xs={12}
              md={4}
              key={i}
              data-aos="fade-up"
              data-aos-delay={i * 100}
              data-aos-offset={100}
              data-aos-duration={600}
            >
              <Box display={'block'} width={1} height={1}>
                <Card>
                  <CardContent
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      padding: 3,
                      bgcolor: 'alternate.dark'
                    }}
                  >
                    <Box
                      component={'img'}
                      src={item.icon}
                      height={0.2}
                      width={0.2}
                    />
                    <Typography sx={{ paddingLeft: 2 }}>
                      {item.name}
                    </Typography>
                  </CardContent>
                </Card>
              </Box>

            </Grid>
          ))}
        </Grid>
      </Grid>
    </Box>

  );
};

export default Blockchains;
