import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import IconButton from '../../../IconButton';
import WaterIcon from '@mui/icons-material/Water';
import { Btc } from 'assets';
import CollectionsIcon from '@mui/icons-material/Collections';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const investments = [
    {
        title: 'Liquidity Pools',
        subtitle:
            'Liquidity pools help make decentralized trading possible, there are pools also for bridges. The annual percentage rates can vary.',
        icon: <WaterIcon htmlColor='lightblue' />,
        href: 'https://docs.athame.finance/investment-types'
    },
    {
        title: 'Coins and Tokens',
        subtitle:
            'Buy and sell various coins and tokens, stake and earn interest and sell when price rises.',
        icon: <Btc />,
        href: 'https://docs.athame.finance/investment-types'
    },
    {
        title: 'Non-Fungible Tokens',
        subtitle:
            'A non-fungible token cannot be replicated or exchanged, this will cause NFTs to appreciate.',
        icon: <CollectionsIcon htmlColor='coral' />,
        href: 'https://docs.athame.finance/investment-types'
    },
    {
        title: 'Validators',
        subtitle:
            'Validators receive rewards for taking up a validator role.',
        icon: <DoneAllIcon htmlColor='green' />,
        href: 'https://docs.athame.finance/investment-types'
    },
    {
        title: 'Staking',
        subtitle:
            'Staking tokens is a very low risk way to earn rewards or interest.',
        icon: <AutoGraphIcon htmlColor='purple' />,
        href: 'https://docs.athame.finance/investment-types'
    },
    {
        title: 'Lending',
        subtitle:
            'Lend our capitol and earn interest.',
        icon: <AccountBalanceIcon htmlColor='#93FFE8' />,
        href: 'https://docs.athame.finance/investment-types'
    }
];

const Investments = (): JSX.Element => {
    const theme = useTheme();
    return (
        <Box
            minHeight='95vh'
            marginTop={5}
            data-aos={'fade-up'}>
            <Box>
                <Typography
                    variant="body2"
                    sx={{
                        textTransform: 'uppercase',
                        fontWeight: 'medium',
                    }}
                    gutterBottom
                    color={'secondary'}
                    align={'center'}
                >
                    Investments
                </Typography>
                <Typography
                    variant="h4"
                    align={'center'}
                    marginBottom={4}
                    data-aos={'fade-up'}
                    gutterBottom
                    sx={{
                        fontWeight: 700,
                        marginTop: theme.spacing(1),
                    }}
                >
                    Investment Types
                </Typography>
                <Grid container spacing={2}>
                    {investments.map((item, i) => (
                        <Grid item xs={12} md={4} key={i}>
                            <Box
                                component={'a'}
                                href={item.href}
                                display={'block'}
                                target='_blank'
                                width={1}
                                height={1}
                                sx={{
                                    textDecoration: 'none',
                                    transition: 'all .2s ease-in-out',
                                    '&:hover': {
                                        transform: `translateY(-${theme.spacing(1 / 2)})`,
                                    },
                                }}
                            >
                                <Box
                                    component={Card}
                                    width={1}
                                    height={1}
                                    data-aos={'fade-up'}
                                    data-aos-delay={i * 100}
                                    data-aos-offset={100}
                                    data-aos-duration={600}
                                    flexDirection={'column'}
                                    display={'flex'}
                                >
                                    <CardContent
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                        }}
                                    >
                                        <IconButton>
                                            {item.icon}
                                        </IconButton>
                                        <Typography
                                            variant={'h6'}
                                            gutterBottom
                                            sx={{ fontWeight: 600 }}
                                        >
                                            {item.title}
                                        </Typography>
                                        <Typography color="text.secondary">
                                            {item.subtitle}
                                        </Typography>
                                    </CardContent>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
};

export default Investments;