import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { APP_URL } from '../../../../constants';
import { Defi } from 'assets';
import {
  eth, ftm,
  matic, avax,
  bnb, arb
} from 'assets';

const Hero = (): JSX.Element => {
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
      minHeight='95vh'
      marginTop={{ xs: 1, md: 20 }}>
      <Grid container spacing={1}>
        <Grid item container xs={12} md={6} alignItems={'center'}>
          <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
            <Box marginBottom={2}>
              <Typography
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: 'medium',
                }}
                gutterBottom
                color={'secondary'}
              >
                DEFI AS A SERVICE
              </Typography>
              <Typography
                variant="h3"
                color="text.primary"
                sx={{ fontWeight: 300 }}
              >
                An all-in-one DeFi solution platform, powered by community governance
              </Typography>
            </Box>
            <Box
              display="flex"
              marginTop={5}
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'flex-start' }}
            >
              <Button

                variant="contained"
                component="a"
                href="https://docs.athame.finance"
                target='_blank'
                color="primary"
                size="large"
                fullWidth={isMd ? false : true}
                endIcon={
                  <Box
                    component={'svg'}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    width={24}
                    height={24}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </Box>
                }
              >
                Learn more
              </Button>
              <Button
                sx={{
                  marginLeft: { sm: 2 },
                  marginTop: { xs: 2, sm: 0 },
                  marginBottom: isMd ? 0 : 3
                }}
                variant="contained"
                component="a"
                href={APP_URL}
                target='_blank'
                color="primary"
                size="large"
                fullWidth={isMd ? false : true}
                endIcon={
                  <Box
                    component={'svg'}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    width={24}
                    height={24}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </Box>
                }
              >
                Launch App
              </Button>
            </Box>
          </Box>
          <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
            <Stack direction='row' spacing={{ xs: 6, md: 2 }} justifyContent='center'
              alignItems='center' >
              <Box
                component={'img'}
                src={avax}
                height={0.05}
                width={0.05}
              />
              <Box
                component={'img'}
                src={matic}
                height={0.05}
                width={0.05}
              />
              <Box
                component={'img'}
                src={eth}
                height={0.05}
                width={0.05}
              />
              <Box
                component={'img'}
                src={ftm}
                height={0.05}
                width={0.05}
              />
              <Box
                component={'img'}
                src={bnb}
                height={0.05}
                width={0.05}
              />
              <Box
                component={'img'}
                src={arb}
                height={0.05}
                width={0.05}
              />
            </Stack>
          </Box>
        </Grid>
        <Grid
          item
          container
          alignItems={'center'}
          justifyContent={'center'}
          xs={12}
          md={6}
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <Box
            component='img'
            height={isMd ? 1 : 0.9}
            width={isMd ? 1 : 0.9}
            src={Defi}
            alt="..."
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;
