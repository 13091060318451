import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { Logo } from '../../../assets';
import { NavItem } from '.';
import { SingleNavItem } from '.';

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSidebarOpen: () => void;
  pages: PageItem[];
}

const Topbar = ({
  onSidebarOpen,
  pages
}: Props): JSX.Element => {
  const theme = useTheme();
  return (

    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        display={'flex'}
        component="a"
        href="/"
        title="Athame Finance"
        width={{ xs: 100, md: 200 }}
      >
        <Box
          component={'img'}
          src={Logo}
          height={1}
          width={1}
        />
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        {pages.map((p) => (
          <Box key={p.id}>
            {p.subItems ?
              <NavItem
                title={p.title}
                id={p.id}
                item={p}
              />
              :
              <SingleNavItem
                title={p.title}
                id={p.id}
                item={p}
              />
            }
          </Box>
        ))}
      </Box>
      <Box sx={{ display: { xs: 'flex', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default Topbar;
