import React from 'react';
import LinkAnimation from '../../LinkAnimation';

interface Props {
    title: string;
    id: string;
    item: PageItem;
}

const SingleNavItem = ({ title,
    id, item
}: Props): JSX.Element => {

    return (
        <LinkAnimation title={title} href={item.href} id={id} target={item.target} />
    );
};

export default SingleNavItem;
