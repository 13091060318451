import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import MuiIconButton, { IconButtonProps } from '@mui/material/IconButton';

const IconButtonRoot = styled(MuiIconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  '&:hover': {
    // Set hover color
    backgroundColor: theme.palette.primary.main
  }

}));

// See https://mui.com/guides/typescript/#usage-of-component-prop for why the types uses `C`.
function IconButton<C extends React.ElementType>(
  props: IconButtonProps<C, { component?: C }>,
) {
  return <IconButtonRoot {...props} />;
}

export default IconButton;