import React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

interface Props {
  title: string;
  item: PageItem;
}

const SingleNavItem = ({ title, item }: Props): JSX.Element => {
  return (
    <Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        sx={{ marginTop: 1, marginBottom: 1 }}
      >
        <Link
          underline="none"
          component="a"
          href={item.href}
          color={'text.primary'}
        >
          <Typography
            color='text.primary'
          >
            {title}
          </Typography>
        </Link>
      </Box>
    </Box>
  );
};

export default SingleNavItem;
