const pages: PageItem[] = [
  {
    title: 'Investments',
    href: '/#investments',
    id: 'investment-page'
  },
  {
    title: 'Roadmap',
    href: '/#roadmap',
    id: 'roadmap-page'
  },
  {
    title: 'Blockchains',
    href: '/#blockchains',
    id: 'blockchains-page'
  },
  {
    title: 'Audit',
    href: '/#audit',
    id: 'audit-page'
  },
  {
    title: 'Documentation',
    href: 'https://docs.athame.finance/',
    target: '_self',
    id: 'doc-page'
  }
];

export default pages;
