import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Main from '../../layout';
import Container from '../../Container';
import Curve from '../../Curve';
import {
  Hero,
  Investments,
  Blockchains,
  Audit,
  Roadmap
} from './components';

const Landing = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Main>
      <Box
        position={'relative'}
        bgcolor={theme.palette.alternate.dark}
        sx={{
          marginTop: -13,
          paddingTop: 13,
        }}
      >
        <Box width={1}
          margin={'0 auto'}
          paddingX={4}
          paddingY={1}>
          <Hero />
        </Box>
        <Curve color={theme.palette.alternate.main} />
      </Box>
      <Box sx={{
        backgroundColor: theme.palette.alternate.main,
      }}>
        <Container id="investments">
          <Investments />
        </Container>
        <Curve color={theme.palette.alternate.dark} />
      </Box>
      <Box sx={{
        backgroundColor: theme.palette.alternate.dark,
      }}>
        <Container id="roadmap">
          <Roadmap />
        </Container>
      </Box>
      <Box
        component={'svg'}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 100.1"
        sx={{
          width: '100%',
          marginBottom: theme.spacing(-1),
        }}
      >
        <path
          fill={theme.palette.background.paper}
          d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
        ></path>
      </Box>
      <Container id="blockchains">
        <Blockchains />
      </Container>
      <Box bgcolor={'alternate.main'}>
        <Container id="audit">
          <Audit />
        </Container>
      </Box>
    </Main>
  );
};

export default Landing;
