import { PaletteMode } from '@mui/material';

export const dark = {
  alternate: {
    main: '#01122D', // background color for pages
    dark: '#010920', // darker color of main
  },
  cardShadow: 'rgba(0, 0, 0, .11)',
  common: {
    black: '#000',
    white: '#fff',
  },
  mode: 'dark' as PaletteMode,
  primary: {
    main: '#1976d2', // button colors etc
    light: '#2196f3', // lighter color of main
    dark: '#0d47a1', // darker color of main
    contrastText: '#fff',
  },
  secondary: {
    main: '#FFE102', // gold color
    light: '#FFEA41', // lighter color of main
    dark: '#DBBE01', // darker color of main
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  text: {
    primary: '#EEEEEF', // almost white
    secondary: '#AEB0B4', // light gray
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  background: {
    paper: '#012347',
    default: '#011B3A',
    level2: '#333',
    level1: '#2D3748',
  },
};
