import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Logo } from '../../../assets';
import DiscordIcon from '../../../assets/DiscordIcon';
import TelegramIcon from '@mui/icons-material/Telegram';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import IconButton from '../../IconButton';
import Divider from '@mui/material/Divider';
import { DISCORD_URL, GITHUB_URL, TELEGRAM_URL, TWITTER_URL } from '../../../constants';

function Copyright() {
  return (
    <React.Fragment>
      {'© '}
      Athame Finance{' '}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

const Footer = (): JSX.Element => {

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={{ xs: 'center', sm: 'flex-start' }}
          width={1}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Box
            sx={{ display: 'flex', flexDirection: 'column' }}
            component="a"
            href="/"
            title="Athame Finance"
            width={120}
          >
            <Box
              component={'img'}
              src={Logo}
              height={1}
              width={1}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography fontWeight="bold" variant="body2" color='secondary.dark'>
              Quick Links
            </Typography>
            <Link
              sx={{
                '&:hover': {
                  color: 'text.secondary'
                },
              }}
              underline="none"
              component="a"
              href="/#investments"
              color="text.primary"
              variant={'subtitle2'}
            >
              Investments
            </Link>
            <Link
              sx={{
                '&:hover': {
                  color: 'text.secondary'
                },
              }}
              underline="none"
              component="a"
              href="/#roadmap"
              color="text.primary"
              variant={'subtitle2'}
            >
              Roadmap
            </Link>
            <Link
              sx={{
                '&:hover': {
                  color: 'text.secondary'
                },
              }}
              underline="none"
              component="a"
              href="/#blockchains"
              color="text.primary"
              variant={'subtitle2'}
            >
              Blockchains
            </Link>
            <Link
              sx={{
                '&:hover': {
                  color: 'text.secondary'
                },
              }}
              underline="none"
              component="a"
              href="/#audit"
              color="text.primary"
              variant={'subtitle2'}
            >
              Audit
            </Link>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography fontWeight="bold" variant="body2" color='secondary.dark'>
              Governance
            </Typography>
            <Link
              sx={{
                '&:hover': {
                  color: 'text.secondary'
                },
              }}
              underline="none"
              component="a"
              href="https://docs.athame.finance/governance"
              color="text.primary"
              variant={'subtitle2'}
            >
              Overview
            </Link>
            <Link
              sx={{
                '&:hover': {
                  color: 'text.secondary'
                },
              }}
              underline="none"
              component="a"
              href="https://docs.athame.finance/governance/process"
              color="text.primary"
              variant={'subtitle2'}
            >
              Process
            </Link>
            <Link
              sx={{
                '&:hover': {
                  color: 'text.secondary'
                },
              }}
              underline="none"
              component="a"
              href="https://docs.athame.finance/governance/ath-governance-token"
              color="text.primary"
              variant={'subtitle2'}
            >
              Token
            </Link>
            <Link
              sx={{
                '&:hover': {
                  color: 'text.secondary'
                },
              }}
              underline="none"
              component="a"
              href="https://docs.athame.finance/governance/eligibility"
              color="text.primary"
              variant={'subtitle2'}
            >
              Eligibility
            </Link>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography fontWeight="bold" variant="body2" color='secondary.dark'>
              Community
            </Typography>
            <Link
              sx={{
                '&:hover': {
                  color: 'text.secondary'
                },
              }}
              underline="none"
              component="a"
              href={TELEGRAM_URL}
              target='_blank'
              color="text.primary"
              variant={'subtitle2'}
            >
              Telegram
            </Link>
            <Link
              sx={{
                '&:hover': {
                  color: 'text.secondary'
                },
              }}
              underline="none"
              component="a"
              href={DISCORD_URL}
              target='_blank'
              color="text.primary"
              variant={'subtitle2'}
            >
              Discord
            </Link>
            <Link
              sx={{
                '&:hover': {
                  color: 'text.secondary'
                },
              }}
              underline="none"
              component="a"
              href={TWITTER_URL}
              target='_blank'
              color="text.primary"
              variant={'subtitle2'}
            >
              Twitter
            </Link>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <IconButton href={TELEGRAM_URL} target='_blank'>
          <TelegramIcon />
        </IconButton>
        <IconButton href={DISCORD_URL} target='_blank'>
          <DiscordIcon />
        </IconButton>
        <IconButton href={GITHUB_URL} target='_blank'>
          <GitHubIcon />
        </IconButton>
        <IconButton href={TWITTER_URL} target='_blank'>
          <TwitterIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          color="text.secondary"
          gutterBottom
        >
          {Copyright()}
        </Typography>
        <Typography
          align={'center'}
          variant={'caption'}
          color="text.secondary"
          component={'p'}
        >
          Use of Athame Finance and the Athame Finance protocol is strictly at your own risk.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
